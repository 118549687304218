import {CdekCalculator} from "../src/cdek_calc";
import {
    order_path,
    cards_orders_path,
    root_path,
    edit_task_path,
    success_order_path,
    edit_profile_path
} from "../routes";
import {showFlashMessage} from "../src/flash";
var $middleNameChek = $('[name="user[profile_attributes][has_middle_name]"]');
import 'bootstrap/js/dist/modal'
import {gotoUrl, submitByUjs} from "../src/common_methods";
import Glide from "@glidejs/glide";
$middleNameChek.on('click', function() {
    $('[name="user[profile_attributes][middle_name]"]').prop('disabled', !this.checked);
});


var $newOrderForm = $('#new_task_adapters_buyout_new_order')

$newOrderForm.on('ajax:success', function(e) {
    let [data, _status, _xhr] = e.originalEvent.detail
    gotoUrl(success_order_path(data.order_id))
})

$newOrderForm.on('ajax:before', function(e) {
    if ($newOrderForm.data('profileReady') == false) {
        e.preventDefault()

        let form = document.getElementById("new_task_adapters_buyout_new_order")
        let formData = new FormData(form)
        let formValues = {}
        formData.forEach(function(value, key){
            formValues[key] = value;
        });
        sessionStorage['preorderParams'] = JSON.stringify(formValues);
        gotoUrl(edit_profile_path())
    }
})

// new CdekCalculator($('#cdek-calc'))

var $signInTab = $('a[href="#sign-in-tab"]')
var $signUpTab = $('a[href="#sign-up-tab"]')

// HACK: tab events not fired
$signUpTab.on('click', (e) => {
    $('#sign-in-tab form').get(0).dispatchEvent(new Event('custom:clearFormErrors'));
})

$signInTab.on('click', (e) => {
    $('#sign-up-tab form').get(0).dispatchEvent(new Event('custom:clearFormErrors'));
})

$(document).on('click', '.decline-details', function(e) {
    $('#decline-details-header').text(e.currentTarget.dataset.header)
    $('#decline-details-body').html(e.currentTarget.dataset.text)
    $('#decline-details-modal').modal('show');
})

function renderUserOrders() {
    const show_number =  localStorage.getItem('order_cards_show_number') || 'all'
    const sort =  localStorage.getItem('order_cards_sort') || 'created_at'
    const sort_direction =  localStorage.getItem('order_cards_sort_direction') || 'desc'
    let page = parseInt(localStorage.getItem('order_cards_page'));
    if (isNaN(page))
        page = 1
    let param_string = {
        show_number,
        sort,
        sort_direction,
        page
    }
    Rails.ajax({
        url: cards_orders_path(param_string),
        type: 'get',
        dataType: 'json',
        success: function(response) {
            if ($(response).find('.order-card').length === 0 && page > 1) {
                localStorage.setItem('order_cards_page', 1);
                renderUserOrders();
            } else {
                $("#orders-list").html(response.body.innerHTML)
            }
        },
        error: function(response) {
            if ($("meta[name=current-user]").length > 0) {
                // gotoUrl(root_path())
            }
        }
    });
}

document.addEventListener('DOMContentLoaded', () => {
    if ($("meta[name=current-user]").length > 0) {
        renderUserOrders();

        if (sessionStorage['preorderParams']) {
            let paramsHash = JSON.parse(sessionStorage['preorderParams'])
            Object.entries(paramsHash).forEach(function([key, value], _num){
                $('[name="' + key + '"]').val(value);
            });
            sessionStorage.removeItem('preorderParams');
            submitByUjs($newOrderForm);
        }
    }
});

$newOrderForm.on('ajax:error', (function(_this) {
    return function(e) {
        var status_code, xhr, _ref;
        _ref = e.detail;
        xhr = _ref[2];
        status_code = xhr.status;
        if (status_code === 401) {
            let form = document.getElementById("new_task_adapters_buyout_new_order")
            let formData = new FormData(form)
            let formValues = {}
            formData.forEach(function(value, key){
                formValues[key] = value;
            });
            sessionStorage['preorderParams'] = JSON.stringify(formValues);
        }
    };
})(this));


$(document).on('click', '.pagination-container a', function (e) {
    e.stopPropagation()
    e.preventDefault()
    let page = new URL(e.currentTarget.href).searchParams.get('page')
    localStorage.setItem('order_cards_page', page)
    renderUserOrders()
})

$(document).on('click', '[data-change-sort]', function (e) {
    localStorage.setItem('order_cards_sort', e.currentTarget.dataset.changeSort.split(' ')[0])
    localStorage.setItem('order_cards_sort_direction', e.currentTarget.dataset.changeSort.split(' ')[1])
    renderUserOrders()
})

// new Glide('.glide', { autoplay: 2500 }).mount();
